import deepmerge from 'deepmerge';
import gql from 'graphql-tag';
import Template from '~/models/Template';
import apolloHandler from '../utils/apolloHandler';

const copy = obj => deepmerge(new Template(), obj);
const serviceName = 'Templates';

export const state = () => ({
  templates: {},
});

export const mutations = {
  SET_TEMPLATE(state, template) {
    const t = copy(template);
    state.templates[template.id] = t;
  },
};

export const getters = {
  getTemplateById: state => (id, copyOfObject = true) => copyOfObject ? copy(state.templates[id]) : state.templates[id],
  getTemplates: state => () => Object.keys(state.templates)
    .map(key => state.templates[key]),
};

const groupOnTemplateFragment = gql`
  fragment group on WidgetTemplate {
    group {
      id
      label: name
    }
  }
`;

const templateGQLProps = `id
name
groupId
isPublic
thumbnailUrl
templateCode {
  wrapperTemplate
  headerTemplate
  footerTemplate
  postTemplate
  postTitle
  postContent
  css
  plugins {
    type
    link
  }
  defaults {
    rows
    columns
    bgColor
    header {
      bgColor
      color
      enabled
      font
      fontSize
      fontWeight
      text
      textAlign
      textTransform
    }
    adLabel {
      bgColor
      color
      enabled
      font
      fontSize
      fontWeight
      text
      textAlign
      textTransform
    }
    postTitle {
      bgColor
      color
      enabled
      font
      fontSize
      fontWeight
      text
      textAlign
      textTransform
    }
    postContent {
      bgColor
      color
      enabled
      font
      fontSize
      fontWeight
      text
      textAlign
      textTransform
    }
  }
}`;

const getCleanObject = obj => JSON.parse(
  JSON.stringify(
    obj, (k, v) => ((k === '__typename') ? undefined : v)
  )
);

const getTemplateInput = template => {
  const templateInput = {
    name: template.name,
    templateCode: {
      wrapperTemplate: template.templateCode.wrapperTemplate,
      headerTemplate: template.templateCode.headerTemplate,
      footerTemplate: template.templateCode.footerTemplate,
      postTemplate: template.templateCode.postTemplate,
      postTitle: template.templateCode.postTitle,
      postContent: template.templateCode.postContent,
      css: template.templateCode.css,
      plugins: template.templateCode.plugins.map(plugin => ({
        type: plugin.type,
        link: plugin.link,
      })),
      defaults: {
        rows: template.templateCode.defaults.rows,
        columns: template.templateCode.defaults.columns,
        bgColor: template.templateCode.defaults.bgColor,
        header: getCleanObject(template.templateCode.defaults.header),
        adLabel: getCleanObject(template.templateCode.defaults.adLabel),
        postTitle: getCleanObject(template.templateCode.defaults.postTitle),
        postContent: getCleanObject(template.templateCode.defaults.postContent),
      },
    },
    isPublic: template.isPublic,
    groupId: template.groupId,
    thumbnailUrl: template.thumbnailUrl,
  };

  return templateInput;
};

export const actions = {
  async insertTemplate({ commit }, template) {
    return new Promise((resolve, reject) => {
      this.app.apolloProvider.defaultClient.mutate({
        mutation: gql `
        mutation ($template: WidgetTemplateInput!) {
          templateInsert(widgetTemplate: $template) {
            ${templateGQLProps}
            ...group
          }
        }
        ${groupOnTemplateFragment}
        `,
        variables: {
          template: getTemplateInput(template),
        },
      }).then(({ data }) => {
        if (data && data.templateInsert) {
          commit('SET_TEMPLATE', data.templateInsert);
          return resolve(data.templateInsert);
        }

        return reject();
      }).catch(reject);
    });
  },

  async updateTemplate({ commit }, template) {
    return new Promise((resolve, reject) => {
      this.app.apolloProvider.defaultClient.mutate({
        mutation: gql `
        mutation ($id: String!, $template: WidgetTemplateInput!) {
          templateUpdate(id: $id, widgetTemplate: $template) {
            ${templateGQLProps}
            ...group
          }
        }
        ${groupOnTemplateFragment}
        `,
        variables: {
          id: template.id,
          template: getTemplateInput(template),
        },
      }).then(({ data }) => {
        if (data && data.templateUpdate) {
          commit('SET_TEMPLATE', data.templateUpdate);
          return resolve(data.templateUpdate);
        }

        return reject();
      }).catch(reject);
    });
  },

  async makeTemplatePrivate({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.app.apolloProvider.defaultClient.mutate({
        mutation: gql `
        mutation ($id: String!) {

          template: makeTemplatePrivate(id: $id) {
            ${templateGQLProps}
            ...group
          }
        }
        ${groupOnTemplateFragment}
        `,
        variables: {
          id,
        },
      }).then(({ data }) => {
        if (data && data.template) {
          commit('SET_TEMPLATE', data.template);
          return resolve(data.template);
        }

        return reject();
      }).catch(reject);
    });
  },

  async makeTemplatePublic({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.app.apolloProvider.defaultClient.mutate({
        mutation: gql `
        mutation ($id: String!) {

          template: makeTemplatePublic(id: $id) {
            ${templateGQLProps}
            ...group
          }
        }
        ${groupOnTemplateFragment}
        `,
        variables: {
          id,
        },
      }).then(({ data }) => {
        if (data && data.template) {
          commit('SET_TEMPLATE', data.template);
          return resolve(data.template);
        }

        return reject();
      }).catch(reject);
    });
  },

  fetchTemplateById({ commit, state }, templateId) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.app.apolloProvider.defaultClient.query({
          query: gql `
          query Template($id: String!){
            Template(id: $id) {
              ${templateGQLProps}
              ...group
            }
          }
          ${groupOnTemplateFragment}
          `,
          variables: {
            id: templateId,
          },
        });

        if (result && result.data && result.data.Template && result.data.Template.id) {
          commit('SET_TEMPLATE', result.data.Template);
          return resolve(result.data.Template);
        }

        reject({ statusCode: 404 });
      } catch (e) {
        console.log(e);
        const error = apolloHandler(e, serviceName);
        reject(error);
      }
    });
  },

  fetchTemplateByWidgetId({ commit }, widgetId) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.app.apolloProvider.defaultClient.query({
          query: gql `
            query ($id: String!) {
              TemplateByWidgetId (id: $id) {
                  ${templateGQLProps}
              }
            }
          `,
          variables: {
            id: widgetId,
          },
        });

        if (result && result.data && result.data.TemplateByWidgetId) {
          commit('SET_TEMPLATE', result.data.TemplateByWidgetId);
          return resolve(result.data.TemplateByWidgetId);
        }

        reject({ statusCode: 500 });
      } catch (e) {
        console.log(e);
        const error = apolloHandler(e, serviceName);
        reject(error);
      }
    });
  },

  fetchTemplates({ commit }, params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { query, pageSize, pageToken } = params;
        const result = await this.app.apolloProvider.defaultClient.query({
          query: gql `
            query ($query: String, $pageSize: Int!, $pageToken: String) {
              Templates (query: $query,
                pagination: { pageSize: $pageSize, pageToken: $pageToken }) {
                widgetTemplates {
                  ${templateGQLProps}
                }
                pageInfo {
                  nextPageToken
                }
              }
            }
          `,
          variables: {
            query: query || '',
            pageSize: pageSize || 0,
            pageToken: pageToken || '0',
          },
        });

        if (result && result.data &&
          result.data.Templates && result.data.Templates.widgetTemplates) {
          result.data.Templates.widgetTemplates
            .forEach(widgetTemplate => commit('SET_TEMPLATE', widgetTemplate));

          const nextPageToken = result.data.Templates.pageInfo ?
            result.data.Templates.pageInfo.nextPageToken || '' : '';

          return resolve({
            widgetTemplates: result.data.Templates.widgetTemplates,
            nextPageToken,
          });
        }

        reject({ statusCode: 500 });
      } catch (e) {
        console.log(e);
        const error = apolloHandler(e, serviceName);
        reject(error);
      }
    });
  },
};
