import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d77194b2 = () => interopDefault(import('../../src/factory/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _fd625a38 = () => interopDefault(import('../../src/factory/pages/instructions.vue' /* webpackChunkName: "pages/instructions" */))
const _91b77792 = () => interopDefault(import('../../src/factory/pages/scrapers/index.vue' /* webpackChunkName: "pages/scrapers/index" */))
const _1f6b09f0 = () => interopDefault(import('../../src/factory/pages/service-desk.vue' /* webpackChunkName: "pages/service-desk" */))
const _18b73c20 = () => interopDefault(import('../../src/factory/pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _91ef06e6 = () => interopDefault(import('../../src/factory/pages/templates/index.vue' /* webpackChunkName: "pages/templates/index" */))
const _9999527a = () => interopDefault(import('../../src/factory/pages/widgets/index.vue' /* webpackChunkName: "pages/widgets/index" */))
const _052685c6 = () => interopDefault(import('../../src/factory/pages/auth/callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _34652e7e = () => interopDefault(import('../../src/factory/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _7bca8c48 = () => interopDefault(import('../../src/factory/pages/bidder/create.vue' /* webpackChunkName: "pages/bidder/create" */))
const _55188d67 = () => interopDefault(import('../../src/factory/pages/scrapers/create.vue' /* webpackChunkName: "pages/scrapers/create" */))
const _4a28674a = () => interopDefault(import('../../src/factory/pages/widgets/create.vue' /* webpackChunkName: "pages/widgets/create" */))
const _b457fa5e = () => interopDefault(import('../../src/factory/pages/widgets/new.vue' /* webpackChunkName: "pages/widgets/new" */))
const _4d21d21a = () => interopDefault(import('../../src/factory/pages/widgets/legacy/create.vue' /* webpackChunkName: "pages/widgets/legacy/create" */))
const _7e6415be = () => interopDefault(import('../../src/factory/pages/bidder/_id/index.vue' /* webpackChunkName: "pages/bidder/_id/index" */))
const _857b28bc = () => interopDefault(import('../../src/factory/pages/scrapers/_id/index.vue' /* webpackChunkName: "pages/scrapers/_id/index" */))
const _a95571ea = () => interopDefault(import('../../src/factory/pages/sites/_id/index.vue' /* webpackChunkName: "pages/sites/_id/index" */))
const _450688f8 = () => interopDefault(import('../../src/factory/pages/templates/_id/index.vue' /* webpackChunkName: "pages/templates/_id/index" */))
const _1f2d582e = () => interopDefault(import('../../src/factory/pages/widgets/_id/index.vue' /* webpackChunkName: "pages/widgets/_id/index" */))
const _0a9519f6 = () => interopDefault(import('../../src/factory/pages/bidder/_id/copy.vue' /* webpackChunkName: "pages/bidder/_id/copy" */))
const _3adfb4d1 = () => interopDefault(import('../../src/factory/pages/bidder/_id/details.vue' /* webpackChunkName: "pages/bidder/_id/details" */))
const _27dee1dc = () => interopDefault(import('../../src/factory/pages/scrapers/_id/details.vue' /* webpackChunkName: "pages/scrapers/_id/details" */))
const _279f2f3f = () => interopDefault(import('../../src/factory/pages/templates/_id/copy.vue' /* webpackChunkName: "pages/templates/_id/copy" */))
const _2666a149 = () => interopDefault(import('../../src/factory/pages/widgets/_id/copy.vue' /* webpackChunkName: "pages/widgets/_id/copy" */))
const _7ef2889e = () => interopDefault(import('../../src/factory/pages/widgets/_id/details.vue' /* webpackChunkName: "pages/widgets/_id/details" */))
const _14c295d6 = () => interopDefault(import('../../src/factory/pages/widgets/_id/newsletter-instructions.vue' /* webpackChunkName: "pages/widgets/_id/newsletter-instructions" */))
const _483d1be2 = () => interopDefault(import('../../src/factory/pages/scrapers/_id/fields/create.vue' /* webpackChunkName: "pages/scrapers/_id/fields/create" */))
const _6c7b8342 = () => interopDefault(import('../../src/factory/pages/scrapers/_id/fields/_fieldId/index.vue' /* webpackChunkName: "pages/scrapers/_id/fields/_fieldId/index" */))
const _50b2bb23 = () => interopDefault(import('../../src/factory/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/faq",
    component: _d77194b2,
    name: "faq"
  }, {
    path: "/instructions",
    component: _fd625a38,
    name: "instructions"
  }, {
    path: "/scrapers",
    component: _91b77792,
    name: "scrapers"
  }, {
    path: "/service-desk",
    component: _1f6b09f0,
    name: "service-desk"
  }, {
    path: "/sites",
    component: _18b73c20,
    name: "sites"
  }, {
    path: "/templates",
    component: _91ef06e6,
    name: "templates"
  }, {
    path: "/widgets",
    component: _9999527a,
    name: "widgets"
  }, {
    path: "/auth/callback",
    component: _052685c6,
    name: "auth-callback"
  }, {
    path: "/auth/login",
    component: _34652e7e,
    name: "auth-login"
  }, {
    path: "/bidder/create",
    component: _7bca8c48,
    name: "bidder-create"
  }, {
    path: "/scrapers/create",
    component: _55188d67,
    name: "scrapers-create"
  }, {
    path: "/widgets/create",
    component: _4a28674a,
    name: "widgets-create"
  }, {
    path: "/widgets/new",
    component: _b457fa5e,
    name: "widgets-new"
  }, {
    path: "/widgets/legacy/create",
    component: _4d21d21a,
    name: "widgets-legacy-create"
  }, {
    path: "/bidder/:id",
    component: _7e6415be,
    name: "bidder-id"
  }, {
    path: "/scrapers/:id",
    component: _857b28bc,
    name: "scrapers-id"
  }, {
    path: "/sites/:id",
    component: _a95571ea,
    name: "sites-id"
  }, {
    path: "/templates/:id",
    component: _450688f8,
    name: "templates-id"
  }, {
    path: "/widgets/:id",
    component: _1f2d582e,
    name: "widgets-id"
  }, {
    path: "/bidder/:id?/copy",
    component: _0a9519f6,
    name: "bidder-id-copy"
  }, {
    path: "/bidder/:id?/details",
    component: _3adfb4d1,
    name: "bidder-id-details"
  }, {
    path: "/scrapers/:id/details",
    component: _27dee1dc,
    name: "scrapers-id-details"
  }, {
    path: "/templates/:id/copy",
    component: _279f2f3f,
    name: "templates-id-copy"
  }, {
    path: "/widgets/:id/copy",
    component: _2666a149,
    name: "widgets-id-copy"
  }, {
    path: "/widgets/:id/details",
    component: _7ef2889e,
    name: "widgets-id-details"
  }, {
    path: "/widgets/:id/newsletter-instructions",
    component: _14c295d6,
    name: "widgets-id-newsletter-instructions"
  }, {
    path: "/scrapers/:id/fields/create",
    component: _483d1be2,
    name: "scrapers-id-fields-create"
  }, {
    path: "/scrapers/:id/fields/:fieldId",
    component: _6c7b8342,
    name: "scrapers-id-fields-fieldId"
  }, {
    path: "/",
    component: _50b2bb23,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
