import gql from 'graphql-tag';
import apolloHandler from '../utils/apolloHandler';

const copy = (obj) => JSON.parse(JSON.stringify(obj));
const serviceName = 'Widgets';

export const state = () => ({
  widgets: {},
});

export const mutations = {
  SET_WIDGET(state, widget) {
    const p = copy(widget);
    p.metaData = p.metaData ? JSON.parse(p.metaData) : {};
    p.settings = p.settings ? JSON.parse(p.settings) : {};
    p.filtersRaw = p.filtersRaw ? JSON.parse(p.filtersRaw) : null;
    p.isPbsEnable = p.isPbsEnable ? p.isPbsEnable : false;
    state.widgets[widget.id] = p;
  },
  ACTIVATE_WIDGET(state, widgetId) {
    state.widgets[widgetId].active = true;
  },
  DEACTIVATE_WIDGET(state, widgetId) {
    state.widgets[widgetId].active = false;
  },
  ARCHIVE_WIDGET(state, widgetId) {
    state.widgets[widgetId].archived = true;
    state.widgets[widgetId].active = true;
  },
};

export const getters = {
  getWidgetById:
    (state) =>
      (id, copyOfObject = true) =>
        copyOfObject ? copy(state.widgets[id]) : state.widgets[id],
  getWidgets: (state) => () =>
    Object.keys(state.widgets).map((key) => state.widgets[key]),
};

export const actions = {
  getPlacement({ commit, state }, placementId) {
    return new Promise(async (resolve, reject) => {
      if (state.widgets[placementId]) {
        return resolve(state.widgets[placementId]);
      }

      try {
        const result = await this.app.apolloProvider.defaultClient.query({
          query: gql`
            query ($id: ID!) {
              GetPlacement(id: $id) {
                id
                isPbsEnable
                improvePlacementId
                improveSiteId
                improveZoneId
                placementSettings {
                  is_readpeak_enabled
                  readpeak_id
                  readpeak_avg_cpm
                  readpeak_ads_share
                  is_vlyby_enable
                  vlyby_publisher_id
                  vlyby_placement_id
                  is_programmatic_impressions_viewable
                  is_lazy_loading_enabled
                  lazy_loading_value_px
                }
                type
                widget: placementData {
                  ... on Widget {
                    id
                    name
                    settings
                    metaData
                    site {
                      id
                      name
                      isDeviceStorageAllowed
                      isPersonalizedAdsAllowed
                    }
                    group {
                      id
                      name
                      currency
                    }
                    widgetType
                    active
                    archived
                    templateRawHtml
                    cssId
                    filtersRaw
                  }
                }
              }
            }
          `,
          variables: {
            id: placementId,
          },
        });

        if (result && result.data && result.data.GetPlacement) {
          result.data.GetPlacement.widget.placementType =
            result.data.GetPlacement.type;
          result.data.GetPlacement.widget.isPbsEnable =
            result.data.GetPlacement.isPbsEnable;
          result.data.GetPlacement.widget.placementSettings =
            result.data.GetPlacement.placementSettings;
          result.data.GetPlacement.widget.improvePlacementId =
            result.data.GetPlacement.improvePlacementId;
          result.data.GetPlacement.widget.improveZoneId =
            result.data.GetPlacement.improveZoneId;
          result.data.GetPlacement.widget.improveSiteId =
            result.data.GetPlacement.improveSiteId;
          commit('SET_WIDGET', result.data.GetPlacement.widget);
          return resolve(result.data.GetPlacement.widget);
        }
        reject({ statusCode: 404 });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        const error = apolloHandler(e, serviceName);
        reject(error);
      }
    });
  },

  fetchWidgets({ commit }, params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { query, groupId, pageSize, pageToken, active, language } =
          params;

        /**
         * You have to explicitly set active to true or false to pass
         * this argument in the request. This is because other falsy
         * values like null, undefined, etc will be treated as false,
         * which is probably not what you want - API will only return
         * inactive widgets in this case.
         **/
        const activeArg = [true, false].includes(active)
          ? ', $active: Boolean'
          : '';
        const activeVal = [true, false].includes(active)
          ? 'active: $active,'
          : '';

        const result = await this.app.apolloProvider.defaultClient.query({
          query: gql`
          query ($query: String, $groupId: ID, $pageSize: Int!, $pageToken: Int ${activeArg}, $language: String) {
            Widgets (
              ${activeVal}
              query: $query,
              groupId: $groupId,
              language: $language,
              pagination: { pageSize: $pageSize, pageToken: $pageToken }) {
              widgets {
                id,
                name,
                settings,
                metaData,
                group {
                  id
                  name
                },
                widgetType,
                active
                archived
                placementType
              }
              pageInfo {
                totalEntries
                pageToken
                pages
              }
            }
          }
          `,
          variables: {
            query: query || '',
            active: active || null,
            groupId: groupId || null,
            pageSize: pageSize || 0,
            pageToken: pageToken || 0,
            language: language || '',
          },
        });

        if (
          result &&
          result.data &&
          result.data.Widgets &&
          result.data.Widgets.widgets
        ) {
          result.data.Widgets.widgets.forEach((widget) =>
            commit('SET_WIDGET', widget)
          );
          return resolve({
            widgets: result.data.Widgets.widgets,
            pageToken: result.data.Widgets.pageInfo.pageToken,
            totalEntries: result.data.Widgets.pageInfo.totalEntries,
            pages: result.data.Widgets.pageInfo.pages,
          });
        }

        return reject({ statusCode: 500 });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        const error = apolloHandler(e, serviceName);
        reject(error);
      }
    });
  },

  async createWidgetPlacement({ commit }, placement) {
    if (placement.widget.settings.contentSettings.source) {
      placement.widget.settings.contentSettings.source.forEach((s) => {
        s.url = s.url.toLowerCase();
      });
    }

    return new Promise((resolve, reject) => {
      this.app.apolloProvider.defaultClient
        .mutate({
          mutation: gql`
            mutation (
              $name: String!
              $isPbsEnable: Boolean!
              $placementSettings: PlacementSettingsInput
              $description: String
              $placementType: PlacementType!
              $siteId: ID!
              $language: String!
              $settings: String!
              $metaData: String!
              $widgetType: WidgetType!
              $active: Boolean!
              $templateRawHtml: String
              $cssId: String
              $filtersRaw: String
            ) {
              createPlacement(
                input: {
                  name: $name
                  isPbsEnable: $isPbsEnable
                  placementSettings: $placementSettings
                  description: $description
                  type: $placementType
                  siteId: $siteId
                  language: $language
                  widget: {
                    name: $name
                    siteId: $siteId
                    settings: $settings
                    metaData: $metaData
                    widgetType: $widgetType
                    active: $active
                    templateRawHtml: $templateRawHtml
                    cssId: $cssId
                    filtersRaw: $filtersRaw
                  }
                }
              ) {
                id
                name
                isPbsEnable
                placementSettings {
                  is_readpeak_enabled
                  readpeak_id
                  readpeak_avg_cpm
                  readpeak_ads_share
                  is_vlyby_enable
                  vlyby_publisher_id
                  vlyby_placement_id
                  is_programmatic_impressions_viewable
                  is_lazy_loading_enabled
                  lazy_loading_value_px
                }
                type
                widget: placementData {
                  ... on Widget {
                    id
                    name
                    settings
                    metaData
                    site {
                      id
                      name
                    }
                    group {
                      id
                      name
                    }
                    widgetType
                    active
                    archived
                    templateRawHtml
                    cssId
                    filtersRaw
                  }
                }
              }
            }
          `,
          variables: {
            name: placement.name,
            isPbsEnable: placement?.isPbsEnable || false,
            placementSettings: {
              is_readpeak_enabled:
                placement?.placementSettings?.is_readpeak_enabled,
              readpeak_id: placement?.placementSettings?.readpeak_id,
              readpeak_avg_cpm: placement?.placementSettings?.readpeak_avg_cpm,
              readpeak_ads_share:
                placement?.placementSettings?.readpeak_ads_share,
              is_vlyby_enable: placement?.placementSettings?.is_vlyby_enable,
              vlyby_publisher_id:
                placement?.placementSettings?.vlyby_publisher_id,
              vlyby_placement_id:
                placement?.placementSettings?.vlyby_placement_id,
              is_programmatic_impressions_viewable:
                placement?.placementSettings
                  ?.is_programmatic_impressions_viewable,
              is_lazy_loading_enabled:
                placement?.placementSettings?.is_lazy_loading_enabled,
              lazy_loading_value_px:
                placement?.placementSettings?.lazy_loading_value_px,
            },
            description: placement.description,
            placementType: placement.placementType,
            siteId: placement.siteId,
            language: placement.language,
            settings: JSON.stringify(placement.widget.settings),
            metaData: JSON.stringify(placement.widget.metaData),
            widgetType: placement.widget.widgetType,
            active: placement.widget.widgetType !== 'LEGACY_WIDGET',
            templateRawHtml: placement.widget.templateRawHtml,
            cssId: placement.widget.cssId,
            filtersRaw: placement.widget.filtersRaw
              ? JSON.stringify(placement.widget.filtersRaw)
              : '',
          },
        })
        .then(({ data }) => {
          if (data && data.createPlacement) {
            data.createPlacement.widget.placementType =
              data.createPlacement.type;
            data.createPlacement.widget.isPbsEnable =
              data.createPlacement.isPbsEnable;
            data.createPlacement.widget.placementSettings =
              data.createPlacement.placementSettings;
            commit('SET_WIDGET', data.createPlacement.widget);
            return resolve(data.createPlacement.widget);
          }

          return reject();
        })
        .catch(reject);
    });
  },

  async updatePlacement({ commit }, widget) {
    if (widget.settings.contentSettings.source) {
      widget.settings.contentSettings.source.forEach((s) => {
        s.url = s.url.toLowerCase();
      });
    }
    // Get language from the legacy config for WIDGET_V1 and API
    if (['WIDGET_V1', 'API'].includes(widget.placementType)) {
      widget.settings.contentSettings.language =
        widget.metaData.legacyData.internal_config.language;
    }

    return new Promise((resolve, reject) => {
      this.app.apolloProvider.defaultClient
        .mutate({
          mutation: gql`
            mutation (
              $id: ID!
              $name: String!
              $isPbsEnable: Boolean!
              $placementSettings: PlacementSettingsInput
              $description: String
              $placementType: PlacementType!
              $siteId: ID!
              $language: String!
              $settings: String!
              $metaData: String!
              $widgetType: WidgetType!
              $active: Boolean!
              $templateRawHtml: String
              $cssId: String
              $filtersRaw: String
            ) {
              updatePlacement(
                id: $id
                input: {
                  name: $name
                  isPbsEnable: $isPbsEnable
                  placementSettings: $placementSettings
                  description: $description
                  type: $placementType
                  siteId: $siteId
                  language: $language
                  widget: {
                    name: $name
                    siteId: $siteId
                    settings: $settings
                    metaData: $metaData
                    widgetType: $widgetType
                    active: $active
                    templateRawHtml: $templateRawHtml
                    cssId: $cssId
                    filtersRaw: $filtersRaw
                  }
                }
              ) {
                id
                name
                isPbsEnable
                placementSettings {
                  is_readpeak_enabled
                  readpeak_id
                  readpeak_avg_cpm
                  readpeak_ads_share
                  is_vlyby_enable
                  vlyby_publisher_id
                  vlyby_placement_id
                  is_programmatic_impressions_viewable
                  is_lazy_loading_enabled
                  lazy_loading_value_px
                }
                type
                widget: placementData {
                  ... on Widget {
                    id
                    name
                    settings
                    metaData
                    site {
                      id
                      name
                    }
                    group {
                      id
                      name
                    }
                    widgetType
                    active
                    archived
                    templateRawHtml
                    cssId
                    filtersRaw
                  }
                }
              }
            }
          `,
          variables: {
            id: widget.id,
            name: widget.name,
            isPbsEnable: widget?.isPbsEnable || false,
            placementSettings: {
              is_readpeak_enabled: widget?.placementSettings?.is_readpeak_enabled,
              readpeak_id: widget?.placementSettings?.readpeak_id,
              readpeak_avg_cpm: widget?.placementSettings?.readpeak_avg_cpm,
              readpeak_ads_share: widget?.placementSettings?.readpeak_ads_share,
              is_vlyby_enable: widget?.placementSettings?.is_vlyby_enable,
              vlyby_publisher_id: widget?.placementSettings?.vlyby_publisher_id,
              vlyby_placement_id: widget?.placementSettings?.vlyby_placement_id,
              is_programmatic_impressions_viewable:
                widget?.placementSettings?.is_programmatic_impressions_viewable,
              is_lazy_loading_enabled:
                widget?.placementSettings?.is_lazy_loading_enabled,
              lazy_loading_value_px:
                widget?.placementSettings?.lazy_loading_value_px,
            },
            placementType: widget.placementType,
            siteId: widget.site.id,
            language: widget.settings.contentSettings.language,
            settings: JSON.stringify(widget.settings),
            metaData: JSON.stringify(widget.metaData),
            widgetType: widget.widgetType,
            active: widget.widgetType !== 'LEGACY_WIDGET',
            groupId: widget.group.id,
            templateRawHtml: widget.templateRawHtml,
            cssId: widget.cssId,
            filtersRaw: widget.filtersRaw
              ? JSON.stringify(widget.filtersRaw)
              : '',
          },
        })
        .then(({ data }) => {
          if (data && data.updatePlacement) {
            data.updatePlacement.widget.placementType =
              data.updatePlacement.type;
            data.updatePlacement.widget.isPbsEnable =
              data.updatePlacement.isPbsEnable;
            data.updatePlacement.widget.placementSettings =
              data.updatePlacement.placementSettings;
            commit('SET_WIDGET', data.updatePlacement.widget);
            return resolve(data.updatePlacement.widget);
          }

          return reject();
        })
        .catch(reject);
    });
  },

  async activateWidget({ commit }, widgetId) {
    return new Promise((resolve, reject) => {
      this.app.apolloProvider.defaultClient
        .mutate({
          mutation: gql`
            mutation ($id: String!) {
              activateWidget(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: widgetId,
          },
        })
        .then(() => {
          commit('ACTIVATE_WIDGET', widgetId);
          resolve();
        })
        .catch(reject);
    });
  },

  async deactivateWidget({ commit }, widgetId) {
    return new Promise((resolve, reject) => {
      this.app.apolloProvider.defaultClient
        .mutate({
          mutation: gql`
            mutation ($id: String!) {
              deactivateWidget(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: widgetId,
          },
        })
        .then(() => {
          commit('DEACTIVATE_WIDGET', widgetId);
          resolve();
        })
        .catch(reject);
    });
  },

  async archiveWidget({ commit }, widgetId) {
    return new Promise((resolve, reject) => {
      this.app.apolloProvider.defaultClient
        .mutate({
          mutation: gql`
            mutation ($id: String!) {
              archiveWidget(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: widgetId,
          },
        })
        .then(() => {
          commit('ARCHIVE_WIDGET', widgetId);
          resolve();
        })
        .catch(reject);
    });
  },
};
