<template>
  <defaultLayout :navigation-items="navigationItems">
    <template slot="search">
      <factory-search />
    </template>
  </defaultLayout>
</template>

<script>
import defaultLayout from '~shared/layouts/default.vue';
import FactorySearch from '../components/FactorySearch';

export default {
  components: {
    defaultLayout,
    FactorySearch,
  },
  data() {
    return {
      navigationItems: this.$settings.getNavigationItems('appSettings.products.factory.navigation.items') || [],
    };
  },
};
</script>
