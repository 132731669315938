export default function generateHash(count = 13, prefix, suffix) {
  let hash = '';
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < count; i++) {
    hash += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  if (prefix) {
    hash = `${prefix}-${hash}`;
  }

  if (suffix) {
    hash = `${hash}-${suffix}`;
  }
  return hash;
}
