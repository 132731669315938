import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faAngleLeft as proFasFaAngleLeft,
  faAngleRight as proFasFaAngleRight,
  faTriangleExclamation as proFasFaTriangleExclamation,
  faCheck as proFasFaCheck,
  faPencil as proFasFaPencil,
  faEllipsis as proFasFaEllipsis,
  faCircleExclamation as proFasFaCircleExclamation,
  faCircleXmark as proFasFaCircleXmark,
  faCircleInfo as proFasFaCircleInfo,
  faCircleQuestion as proFasFaCircleQuestion,
  faCirclePause as proFasFaCirclePause,
  faCirclePlay as proFasFaCirclePlay,
  faXmark as proFasFaXmark,
  faBars as proFasFaBars,
  faMagnifyingGlass as proFasFaMagnifyingGlass,
  faBoxArchive as proFasFaBoxArchive,
  faSpinner as proFasFaSpinner,
  faPlus as proFasFaPlus,
  faTrash as proFasFaTrash,
  faClipboardCheck as proFasFaClipboardCheck,
  faArrowUpRightFromSquare as proFasFaArrowUpRightFromSquare,
  faChevronDown as proFasFaChevronDown,
  faCog as proFasFaCog,
  faAngleDown as proFasFaAngleDown,
  faAngleUp as proFasFaAngleUp,
  faExternalLinkAlt as proFasFaExternalLinkAlt,
  faBalanceScale as proFasFaBalanceScale,
  faClone as proFasFaClone,
  faChartPie as proFasFaChartPie,
  faArrowRight as proFasFaArrowRight,
  faArrowLeft as proFasFaArrowLeft,
  faEye as proFasFaEye,
  faLock as proFasFaLock,
  faImage as proFasFaImage,
  faUser as proFasFaUser,
  faAlignLeft as proFasFaAlignLeft,
  faAlignCenter as proFasFaAlignCenter,
  faAlignRight as proFasFaAlignRight,
  faTableColumns as proFasFaTableColumns,
  faDesktop as proFasFaDesktop,
  faMobileScreen as proFasFaMobileScreen,
  faLaptop as proFasFaLaptop,
  faCode as proFasFaCode,
  faCloudArrowUp as proFasFaCloudArrowUp,
  faUp as proFasFaUp,
  faDown as proFasFaDown
} from '@fortawesome/pro-solid-svg-icons'

import {
  faClipboard as proFarFaClipboard,
  faCircleQuestion as proFarFaCircleQuestion,
  faWindowMaximize as proFarFaWindowMaximize,
  faImage as proFarFaImage,
  faEnvelope as proFarFaEnvelope,
  faBracketsCurly as proFarFaBracketsCurly,
  faArrowRightArrowLeft as proFarFaArrowRightArrowLeft
} from '@fortawesome/pro-regular-svg-icons'

import {
  faList as proFalFaList,
  faCirclePlus as proFalFaCirclePlus,
  faGlobe as proFalFaGlobe,
  faSpider as proFalFaSpider
} from '@fortawesome/pro-light-svg-icons'

library.add(
  proFasFaAngleLeft,
  proFasFaAngleRight,
  proFasFaTriangleExclamation,
  proFasFaCheck,
  proFasFaPencil,
  proFasFaEllipsis,
  proFasFaCircleExclamation,
  proFasFaCircleXmark,
  proFasFaCircleInfo,
  proFasFaCircleQuestion,
  proFasFaCirclePause,
  proFasFaCirclePlay,
  proFasFaXmark,
  proFasFaBars,
  proFasFaMagnifyingGlass,
  proFasFaBoxArchive,
  proFasFaSpinner,
  proFasFaPlus,
  proFasFaTrash,
  proFasFaClipboardCheck,
  proFasFaArrowUpRightFromSquare,
  proFasFaChevronDown,
  proFasFaCog,
  proFasFaAngleDown,
  proFasFaAngleUp,
  proFasFaExternalLinkAlt,
  proFasFaBalanceScale,
  proFasFaClone,
  proFasFaChartPie,
  proFasFaArrowRight,
  proFasFaArrowLeft,
  proFasFaEye,
  proFasFaLock,
  proFasFaImage,
  proFasFaUser,
  proFasFaAlignLeft,
  proFasFaAlignCenter,
  proFasFaAlignRight,
  proFasFaTableColumns,
  proFasFaDesktop,
  proFasFaMobileScreen,
  proFasFaLaptop,
  proFasFaCode,
  proFasFaCloudArrowUp,
  proFasFaUp,
  proFasFaDown,
  proFarFaClipboard,
  proFarFaCircleQuestion,
  proFarFaWindowMaximize,
  proFarFaImage,
  proFarFaEnvelope,
  proFarFaBracketsCurly,
  proFarFaArrowRightArrowLeft,
  proFalFaList,
  proFalFaCirclePlus,
  proFalFaGlobe,
  proFalFaSpider
)

config.autoAddCss = false

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-iconLayers', FontAwesomeLayers)
Vue.component('fa-iconLayersText', FontAwesomeLayersText)
