import deepmerge from 'deepmerge';
import gql from 'graphql-tag';
import apolloHandler from '../utils/apolloHandler';

const copy = obj => deepmerge({}, obj);

const serviceName = 'Groups';

export const state = () => ({
  groups: {},
});

export const mutations = {
  SET_GROUP(st, group) {
    const g = copy(group);
    st.groups[group.id] = g;
  },
};

export const getters = {
  getGroups: st => () => Object.keys(st.groups)
    .map(key => st.groups[key])
    .sort((a, b) => a.name.localeCompare(b.name)),
  getGroupById: st => (id) => st.groups[id],
};

export const actions = {
  async fetchGroups({ commit }, params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { pageSize } = params;
        const result = await this.app.apolloProvider.defaultClient.query({
          query: gql `
            query ($limit: Int!) {
              Groups (limit: $limit) {
                groups {
                  id
                  name
                }
                pageInfo {
                  nextPageToken
                }
              }
            }
          `,
          variables: {
            limit: pageSize || 0,
          },
        });

        if (result && result.data &&
          result.data.Groups && result.data.Groups.groups) {
          result.data.Groups.groups
            .forEach(group => commit('SET_GROUP', group));

          const nextPageToken = result.data.Groups.pageInfo ?
            result.data.Groups.pageInfo.nextPageToken || '' : '';

          return resolve({
            groups: result.data.Groups.groups,
            nextPageToken,
          });
        }

        reject({ statusCode: 500 });
      } catch (e) {
        console.log(e);
        const error = apolloHandler(e, serviceName);
        reject(error);
      }
    });
  },
};
