import Vue from 'vue';

Vue.use({
  install(vue, options) {
    vue.prototype.$isLegacy = (widget) => {
      return widget.widgetType === 'LEGACY_WIDGET';
    };

    vue.prototype.$getWidgetId = (widget) => {
      try {
        if (widget.widgetType === 'LEGACY_WIDGET') {
          return widget.metaData.legacyData.legacyId || widget.id;
        }
      } catch (e) {
        // sometimes needs parsing depending on what view uses it
        try {
          return JSON.parse(widget.metaData).legacyData.legacyId || widget.id;
        } catch (e2) {
          // skip
        }
      }

      return widget.id;
    };
  },
});
