import generateHash from '~/utils/hashGenerator';

const Template = () => ({
  id: '',
  name: '',
  isPublic: false,
  groupId: '1', // Strossle International Group ID by default
  cssId: generateHash(),
  templateCode: {
    css: '',
    wrapperTemplate: '',
    headerTemplate: '',
    postTemplate: '',
    postTitle: '',
    postContent: '',
    footerTemplate: '',
    plugins: [],
    defaults: {},
  },
  getCssId() {
    return this.cssId;
  },
  getWrapperTemplate() {
    return this.templateCode.wrapperTemplate.replace(/{{cssId}}/g, this.cssId);
  },
  getHeaderTemplate() {
    return this.templateCode.headerTemplate.replace(/{{cssId}}/g, this.cssId);
  },
  getPostTemplate(columns, titleEnabled, contentEnabled) {
    let columnClass = '';

    switch (columns) {
    case 6:
      columnClass = 'col-sm-2';
      break;
    case 4:
      columnClass = 'col-sm-3';
      break;
    case 3:
      columnClass = 'col-sm-4';
      break;
    case 2:
      columnClass = 'col-sm-6';
      break;
    default:
      columnClass = 'col-sm-12';
    }

    const postTitle = titleEnabled ? this.templateCode.postTitle : '';
    const postContent = contentEnabled ? this.templateCode.postContent : '';

    let postTemplate = this.templateCode.postTemplate.replace('{{columnClass}}', columnClass);
    postTemplate = postTemplate.replace('{{widgetPostTitle}}', postTitle);
    postTemplate = postTemplate.replace('{{widgetPostContent}}', postContent);
    postTemplate = postTemplate.replace(/{{cssId}}/g, this.cssId);

    return postTemplate;
  },
  getFooterTemplate() {
    return this.templateCode.footerTemplate.replace(/{{cssId}}/g, this.cssId);
  },
  compileCSS(styleValues) {
    let cssTemplate = this.templateCode.css;

    const variablesMap = {
      backgroundColor: 'backgroundColor',
      header: {
        backgroundColor: 'header.backgroundColor',
        fontColor: 'header.color',
        align: 'header.textAlign',
        font: 'header.font',
        fontSize: 'header.fontSize',
        fontWeight: 'header.fontWeight',
      },
      advertisementLabel: {
        backgroundColor: 'label.backgroundColor',
        fontColor: 'label.color',
        textTransform: 'label.textTransform',
        font: 'label.font',
        fontSize: 'label.fontSize',
        fontWeight: 'label.fontWeight',
      },
      postTitle: {
        fontColor: 'title.color',
        align: 'title.textAlign',
        textTransform: 'title.textTransform',
        font: 'title.font',
        fontSize: 'title.fontSize',
        fontWeight: 'title.fontWeight',
      },
      postContent: {
        fontColor: 'content.color',
        align: 'content.textAlign',
        textTransform: 'content.textTransform',
        font: 'content.font',
        fontSize: 'content.fontSize',
        fontWeight: 'content.fontWeight',
      },
    };

    Object.keys(variablesMap).forEach((key) => {
      const toReplace = variablesMap[key];
      if (typeof toReplace === 'string') {
        if (styleValues.hasOwnProperty(key)) {
          cssTemplate = cssTemplate.replace(`{{${toReplace}}}`, styleValues[key]);
        }
      } else {
        Object.keys(toReplace).forEach((innerKey) => {
          const innerReplace = toReplace[innerKey];
          if (styleValues.hasOwnProperty(key) && styleValues[key].hasOwnProperty(innerKey)) {
            cssTemplate = cssTemplate.replace(`{{${innerReplace}}}`, styleValues[key][innerKey]);
          }
        });
      }
    });

    if (styleValues.hasOwnProperty('advertisementLabel') &&
      styleValues.advertisementLabel.hasOwnProperty('placement')) {
      switch (styleValues.advertisementLabel.placement) {
      case 'above-title':
        cssTemplate = cssTemplate.replace('{{label.placementOnImage}}', 'none');
        cssTemplate = cssTemplate.replace('{{label.placementAboveTitle}}', 'inline-block');
        cssTemplate = cssTemplate.replace('{{label.placementBelowContent}}', 'none');
        break;
      case 'below-content':
        cssTemplate = cssTemplate.replace('{{label.placementOnImage}}', 'none');
        cssTemplate = cssTemplate.replace('{{label.placementAboveTitle}}', 'none');
        cssTemplate = cssTemplate.replace('{{label.placementBelowContent}}', 'inline-block');
        break;
      default:
        cssTemplate = cssTemplate.replace('{{label.placementOnImage}}', 'inline-block');
        cssTemplate = cssTemplate.replace('{{label.placementAboveTitle}}', 'none');
        cssTemplate = cssTemplate.replace('{{label.placementBelowContent}}', 'none');
      }
    }

    if (styleValues.hasOwnProperty('additionalCss')) {
      cssTemplate += styleValues.additionalCss;
    }

    cssTemplate = cssTemplate.replace(/{{cssId}}/g, this.cssId);
    return cssTemplate;
  },
  getPlugins() {
    const plugins = [];
    for (let i = 0; i < this.templateCode.plugins.length; i++) {
      plugins.push({
        link: this.templateCode.plugins[i].link,
        type: this.templateCode.plugins[i].type,
      });
    }
    return plugins;
  },
});

export default Template;
