<template>
  <div class="search">
    <multiselect
      ref="factorysearch"
      :options="options"
      :multiple="false"
      :group-select="true"
      :show-labels="false"
      :loading="$apollo.queries.options.loading"
      :preserve-search="false"
      :internal-search="false"
      :max-height="600"
      :show-no-options="false"
      placeholder="Search for widgets"
      track-by="name"
      label="name"
      @search-change="search"
      @close="clear"
      @select="goto"
    >
      <template slot="option" slot-scope="props">
        <div class="option">
          <span class="option__desc">
            <span class="option__title">
              <div v-html="props.option.searchName || props.option.name"></div>
              <div class="mt-1">
                <span class="secondary-line">
                  {{ props.option.group.name }}
                </span>
              </div>
            </span>
          </span>
        </div>
      </template>
      <template slot="caret" slot-scope="props">
        <span class="icon">
          <fa-icon icon="magnifying-glass" />
        </span>
      </template>

      <span slot="noResult">No result matched your query.</span>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import gql from 'graphql-tag';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      query: '', // hold search query
      options: [],
    };
  },
  mounted() {
    this.$root.$on('mobile-search-focus', () => {
      this.$refs.factorysearch.$el.focus();
    });
  },
  apollo: {
    options: {
      query: gql`
        query($query: String!) {
          Widgets(query: $query) {
            widgets {
              id
              name
              placementType
              group {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return {
          query: this.query.trim(),
        };
      },
      skip() {
        return !this.query || this.query.length < 3;
      },
      update(data) {
        let options = [];
        if (data && data.Widgets && Array.isArray(data.Widgets.widgets)) {
          options = data.Widgets.widgets;
        }

        if (this.query) {
          // Here we mark the search result, bold text if matching the query.
          const regexp = new RegExp(this.query, 'gi');
          options.forEach(o => {
            o.searchName = o.name.replace(
              regexp,
              match => `<strong>${match}</strong>`
            );
          });
        }

        this.options = options;
        return this.options;
      },
      debounce: 250,
    },
  },
  methods: {
    search(q) {
      this.query = q;
    },
    clear() {
      this.sendGaEvent();
      this.query = '';
      this.options = [];
      this.$root.$emit('mobile-search-unfocused');
    },
    goto(item) {
      this.sendGaEvent();
      const path = item.placementType === 'BIDDER' ? 'bidder' : 'widgets';
      this.$router.push(`/${path}/${item.id}/details`);
    },
    sendGaEvent() {
      if (!this.$ga || !this.query) {
        return;
      }
      this.$ga.event('search', 'query', this.query);
    },
  },
};
</script>

<style scoped>
.multiselect {
  min-height: 30px !important;
  color: #5c6873 !important;
  font-size: 0.76562rem !important;
  margin-bottom: 0 !important;
  height: 31px !important;
}
.search >>> .multiselect .multiselect__select {
  top: 0;
  min-height: 30px;
  height: 100%;
  max-height: 40px;
}
.search >>> .multiselect .multiselect__single {
  margin-bottom: 0px;
  padding: 0px !important;
  font-size: 0.76562rem !important;
  margin-bottom: 0 !important;
}
.search >>> .multiselect .multiselect__input {
  border-radius: 0.2rem !important;
  vertical-align: baseline !important;
  margin-bottom: 0 !important;
  max-height: 31px !important;
  min-height: auto !important;
  padding: 0;
  display: inline !important;
}
.search >>> .multiselect .multiselect__option--group {
  background-color: #ffffff;
  color: #bbbec1;
  text-transform: uppercase;
  font-weight: 600;
  height: calc(1.53em + 0.6rem) !important;
}
.search >>> .multiselect .multiselect__tags {
  border-radius: 0.2rem !important;
  border: 1px solid #e4e7ea !important;
  display: block;
  padding: 0.25rem 30px 0.25rem 0.5rem !important;
  min-height: 30px !important;
  margin-bottom: 0 !important;
  height: 31px !important;
}
.search >>> .multiselect .multiselect__placeholder {
  color: #5c6873 !important;
  font-size: 0.76562rem !important;
  margin-bottom: 0 !important;
  vertical-align: baseline !important;
  display: inline;
  padding: 0;
}
.option {
  border-left: 3px solid;
  border-left-color: #af6aac;
  padding-left: 5px;
}
.icon {
  color: #4d92e3;
  float: right;
  margin-right: 10px;
  margin-top: 7px;
}
.secondary-line {
  font-family: 'montserrat';
  font-size: 0.85em;
  font-style: italic;
}
</style>
