<template>
  <errorLayout />
</template>

<script>
import errorLayout from '~shared/layouts/error.vue';

export default {
  components: {
    errorLayout,
  },
};
</script>
