export default (res, serviceName) => {
  if (res &&
    res.graphQLErrors &&
    res.graphQLErrors.length &&
    res.graphQLErrors[0].extensions &&
    res.graphQLErrors[0].extensions.exception &&
    res.graphQLErrors[0].extensions.exception.code) {

    switch(res.graphQLErrors[0].extensions.exception.code) {
    case 13: // widget not found error code
    case 5: // widget not found error code
    case 2: // template not found error code
      return {
        statusCode: 404,
        message: 'Not found',
      };

    case 14: // UNAVAILABLE: Connect failed
      return {
        statusCode: 503,
        message: serviceName
          ? `${serviceName} service unavailable`
          : 'Service unavailable',
      };

    default:
      return {
        statusCode: 500,
        message: 'Internal error',
      };
    }
  }

  return { statusCode: 200 };
};
