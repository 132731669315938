<template>
  <div class="app">
    <AppHeader fixed>
      <div class="navbar-brand">
        <img
          src="/img/strosslelogo.svg"
          width="auto"
          height="18"
          alt="Strossle">
      </div>
    </AppHeader>
    <div class="app-body">
      <main class="main py-4">
        <div class="container-fluid pt-2">
          <nuxt/>
        </div>
      </main>
    </div>
    <vue-snotify />
  </div>
</template>

<script>
import AppHeader from '~shared/components/commons/AppHeader.vue';

export default {
  components: {
    AppHeader,
  },
};
</script>
